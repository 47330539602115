import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { navigation } from "./apis";
import { navContext } from "./context/contextApi";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Preloader from "./components/Preloader";

const AboutUs = lazy(() => import("./Pages/AboutUs"));
const Contact = lazy(() => import("./Pages/Contact"));
const Factories = lazy(() => import("./Pages/Factories/Factories"));
const FactoryList = lazy(() => import("./Pages/Factories/FactoryList"));
const Faq = lazy(() => import("./Pages/Faq/index"));
const Footer = lazy(() => import("./components/Wrapper/Footer"));
const Home = lazy(() => import("./Pages/Home"));
const MediaNews = lazy(() => import("./Pages/Media&News/MediaNews"));
const MediaNewsDetails = lazy(() =>
  import("./Pages/Media&News/MediaNewsDetails")
);
const Navbar = lazy(() => import("./components/Wrapper/Navbar"));
const Privacy = lazy(() => import("./Pages/Privacy"));
const Terms = lazy(() => import("./Pages/Terms"));
const MobileNavbar = lazy(() => import("./components/Wrapper/MobileNavbar"));
const ProductDetails = lazy(() => import("./Pages/Products/ProductDetails"));
const ProductLists = lazy(() => import("./Pages/Products/ProductLists"));
const ProductPortFolio = lazy(() =>
  import("./Pages/Products/ProductPortFolio")
);
const Products = lazy(() => import("./Pages/Search/Products"));
const ProjectDetails = lazy(() => import("./Pages/Projects/ProjectDetails"));
const ProjectLists = lazy(() => import("./Pages/Projects/ProjectsList"));
const RoomVisualize = lazy(() => import("./Pages/Simulation/RoomVisualize"));
const Services = lazy(() => import("./Pages/Services/Services"));
const Turnkey = lazy(() => import("./Pages/Services/Turnkey"));
const ServicesDelivery = lazy(() =>
  import("./Pages/Services/ServicesDelivery")
);
const ServicesList = lazy(() => import("./Pages/Services/ServicesList"));
const ExpCenter = lazy(() => import("./Pages/Experience"));
const Simulator = lazy(() => import("./Pages/Simulation/Simulator"));
const StoreLocator = lazy(() => import("./Pages/StoreLocation/StoreLocator"));

function App() {
  const [navFooter, setNavFooter] = useState(null);
  const { Provider } = navContext;

  const fetchNavs = async () => {
    try {
      const result = await navigation();
      setNavFooter(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!navFooter) {
      fetchNavs();
    }
  }, []);

  // useEffect(() => {
  //   console.log("mounted");
  //   return () => {
  //     console.log("unmounted");
  //   }
  // })

  return (
    <Provider value={navFooter}>
      <Router>
        <Suspense fallback={<Preloader />}>
          {/* <Navbar/> */}
          <Switch>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Home />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={Home}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/about-us"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <AboutUs />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={AboutUs}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/contact"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Contact />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={Contact}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/factory-and-stockyard"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Factories props={props} />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={Factories}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/factory-details"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <FactoryList />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={FactoryList}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/faq"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Faq />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={Faq}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/media-and-news"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <MediaNews />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={MediaNews}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/media-and-news/:slug"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <MediaNewsDetails props={props} />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={MediaNewsDetails}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/privacy-policy"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Privacy />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={Privacy}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/terms-&-conditions"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Terms />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/product-portfolio/:slug"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <ProductDetails props={props} />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={ProductDetails}
            />
            {/* <Route 
          exact
          path="/productLists"
          render={props => {
            return (
              <>
              <Navbar/>
              <ProductLists/>
              <Footer/>
              <MobileNavbar/>
              </>
            )
          }}
          // component={ProductLists}
        /> */}
            <Route
              exact
              path={process.env.PUBLIC_URL + "/product-portfolio"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <ProductPortFolio props={props} />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={ProductPortFolio}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/search"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Products props={props} />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={Products}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/our-projects"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <ProjectLists />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={ProjectLists}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/our-projects/:project"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <ProjectDetails props={props} />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={ProjectDetails}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/contact"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Contact />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={Contact}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/our-services"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Services props={props} />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={Services}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/delivery-services"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <ServicesDelivery />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={ServicesDelivery}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/turnkey-solution"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <Turnkey />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={ServicesDelivery}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/store-locator"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <StoreLocator />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={StoreLocator}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/simulator"}
              component={Simulator}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/experience-center"}
              render={(props) => {
                return (
                  <>
                    <Navbar />
                    <ExpCenter props={props} />
                    <Footer />
                    <MobileNavbar />
                  </>
                );
              }}
              // component={StoreLocator}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + "/virtual-tour"}
              component={RoomVisualize}
            />
          </Switch>
          {/* <Footer/> */}
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
