import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ToastProvider } from "react-toast-notifications";
import App from "./App";
import "./styles/style.scss";
import * as serviceWorker from "./serviceWorker";
import "react-image-lightbox/style.css";
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <ToastProvider placement="bottom-left">
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </ToastProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
