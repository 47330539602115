import Axios from 'axios';
import axiosRetry from 'axios-retry';

const BASE_URL = 'https://api.artistrymarble.com';

const instance = Axios.create({
  baseURL: BASE_URL,
});
axiosRetry(instance, { retries: 3 });

export const getHomePage = async (token) => {
  return await instance.get('/api/pages/home', { cancelToken: token });
};

export const getFaqPage = async (token) => {
  return await instance.get('/api/pages/faqs', { cancelToken: token });
};

export const sendFaq = async (data) => {
  return await instance.post('/api/faq', null, {
    params: {
      name: data.name,
      email: data.email,
      subject: data.subject,
      phone: data.phone_number,
      message: data.message,
    },
  });
};

export const getInTouch = async (data) => {
  return await instance.post('/api/contact-us', null, {
    params: {
      ...data,
    },
  });
};

export const getStoresList = async (token) => {
  return await instance.get('/api/stores', { cancelToken: token });
};

export const getPortfolioPage = async (token) => {
  return await instance.get('/api/pages/product-portfolio', {
    cancelToken: token,
  });
};

export const productDetails = async (slug, token) => {
  return await instance.get(`/api/products/${slug}`, { cancelToken: token });
};

export const navigation = async () => {
  return await instance.get('/api/navigation');
};

export const getFilteredProductsList = async (params, token) => {
  return await instance.get('/api/pages/product-portfolio', {
    params,
    cancelToken: token,
  });
};

export const getServicePage = async (token) => {
  return await instance.get('/api/pages/our-services', { cancelToken: token });
};

export const getExpCenterPage = async (token) => {
  return await instance.get('/api/pages/experience-center', {
    cancelToken: token,
  });
};

export const getFilteredServicesList = async (params, token) => {
  return await instance.get('/api/pages/our-services', {
    params,
    cancelToken: token,
  });
};

export const getFactoryPage = async (token) => {
  return await instance.get('/api/pages/factory-and-stockyard', {
    cancelToken: token,
  });
};

export const getFilteredFactoriesList = async (params, token) => {
  return await instance.get('/api/pages/factory-and-stockyard', {
    params,
    cancelToken: token,
  });
};

export const getProjectPage = async (params, token) => {
  return await instance.get('/api/pages/our-projects', {
    params,
    cancelToken: token,
  });
};

export const getNewsPage = async (params, token) => {
  return await instance.get('/api/pages/media-and-news', {
    params,
    cancelToken: token,
  });
};

export const getSearchResult = async (params, token) => {
  return await instance.get('/api/pages/search', {
    params,
    cancelToken: token,
  });
};

export const productSearch = async (stringValue, abortSignal) => {
  const url = new URL(BASE_URL + '/api/pages/search');

  const params = { query: stringValue, page: 1 };

  url.search = new URLSearchParams(params).toString();

  const result = await fetch(url, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    signal: abortSignal,
  });
  const json = await result.json();
  // console.log("searching", json);
  return json;
};

export const getSimulation = async (token) => {
  return await instance.get('/api/pages/simulator', { cancelToken: token });
};

export const getSlides = async (token) => {
  return await instance.get('/api/home-slider', { cancelToken: token });
};

export const getProdCategories = async (token) => {
  return await instance.get('/api/products/categories', { cancelToken: token });
};

export const getAboutUsPage = async (token) => {
  return await instance.get('/api/pages/about-us', { cancelToken: token });
};

export const getContactUs = async (token) => {
  return await instance.get('/api/pages/contact-with-us', {
    cancelToken: token,
  });
};

export const getTerms = async (token) => {
  return await instance.get('/api/pages/terms-and-conditions', {
    cancelToken: token,
  });
};

export const getPrivacy = async (token) => {
  return await instance.get('/api/pages/privacy-policy', {
    cancelToken: token,
  });
};
