import React from "react";

export default function Preloader() {
  return (
    <div className="art-preloader-wrapper">
      <div className="art-preloader">
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
